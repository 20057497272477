import React, { Component } from 'react';
import Spinner from '../Spinner/Spinner';
import axios from 'axios';
import './ForgotPW.css';
import data from '../assets/pagecopy.json';

const API_ROUTE = '/login/api/v1/';

export class ForgotPW extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			initial: true,
			loading: false,
			invalid: false,
			duplicate: false,
			success: false,
			usernames: [],
			selectedUsername: '',
			showError: false,
			errorMessage: '',
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e, pagecopy) => {
		e.preventDefault();
		let isErrors = false;
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let message = '';
		if (!re.test(this.state.email)) {
			isErrors = true;
			message = pagecopy.main.invalid;
		}
		if (!this.state.email) {
			isErrors = true;
			message = pagecopy.main.required;
		}
		if (isErrors) {
			this.setState({ showError: true, errorMessage: message });
		} else {
			const params = {
				email: this.state.email,
			};
			if (this.state.selectedUsername)
				params.username = this.state.selectedUsername;
			this.setState(
				{
					loading: true,
					invalid: false,
					duplicate: false,
					success: false,
					initial: false,
					showError: false,
					errorMessage: '',
				},
				() => {
					axios({
						url: `${API_ROUTE}forgotpassword`,
						method: 'post',
						data: {
							...params,
						},
					})
						.then((res) => {
							if (
								res.data.progress &&
								res.data.progress === 'invalid'
							) {
								this.setState({
									loading: false,
									invalid: true,
								});
							} else if (
								res.data.progress &&
								res.data.progress === 'duplicate'
							) {
								this.setState({
									loading: false,
									duplicate: true,
									usernames: res.data.duplicates,
								});
							} else if (
								res.data.progress &&
								res.data.progress === 'found'
							) {
								this.setState({
									loading: false,
									success: true,
								});
							} else {
								this.setState({
									loading: false,
									showError: true,
									errorMessage:
										'There was an error processing your request. Please try again.',
								});
							}
						})
						.catch((error) => {
							console.error(error);
							this.setState({ loading: false });
						});
				}
			);
		}
	};

	render(props) {
		const pagecopy = data[this.props.lang].forgotpw;
		const unknown = (
			<>
				<h2>{pagecopy.unknown.title}</h2>
				<p>{pagecopy.unknown.subtitle1}</p>
				<p>
					{pagecopy.unknown.subtitle2}
					<span className='bold'>AgSolutions</span>
					{pagecopy.unknown.subtitle3}
				</p>
			</>
		);
		const duplicates = (
			<>
				<h2>{pagecopy.multiple.title}</h2>
				<div className='duplicate-content'>
					<p>{pagecopy.multiple.subtitle1}</p>
					<p>{pagecopy.multiple.subtitle2}</p>
					<select
						className='duplicates-dropdown'
						name='selectedUsername'
						onChange={this.handleChange}
					>
						<option value>{pagecopy.multiple.dropdown}</option>
						{this.state.usernames.map((name, index) => (
							<option value={name} key={index}>
								{name}
							</option>
						))}
					</select>
				</div>
				<div className='submission-row'>
					<div className='submission-item'>
						<button
							className='button'
							name='cancel'
							onClick={() => {
								this.props.toggleForgotPW();
							}}
						>
							{pagecopy.multiple.cancel}
						</button>
					</div>
					<div className='submission-item'>
						<button
							className='button'
							name='submit'
							onClick={(e) => {
								this.handleSubmit(e, pagecopy);
							}}
						>
							{pagecopy.multiple.submit}
						</button>
					</div>
				</div>
			</>
		);

		const success = (
			<>
				<h2>{pagecopy.success.title}</h2>
				<div className='success-content'>
					<p>{pagecopy.success.subtitle1}</p>
					<p>{pagecopy.success.subtitle2}</p>
				</div>
				<div className='submission-row'>
					<div className='submission-item'>
						<button
							className='button success'
							name='cancel'
							onClick={() => {
								this.props.toggleForgotPW();
							}}
						>
							{pagecopy.success.button}
						</button>
					</div>
				</div>
			</>
		);
		return (
			<>
				{this.state.loading && <Spinner />}
				{this.state.invalid && unknown}
				{this.state.duplicate && duplicates}
				{this.state.success && success}
				{this.state.initial && (
					<>
						<h2>{pagecopy.main.title}</h2>
						<p>{pagecopy.main.subtitle}</p>
						<p
							className={`error-message ${
								this.state.showError ? 'show' : ''
							}`}
						>
							{this.state.errorMessage}
						</p>
					</>
				)}
				{(this.state.initial || this.state.invalid) && (
					<form>
						<div className='input-row'>
							<label htmlFor='email' className='input-label'>
								{pagecopy.main.email}*
							</label>
							<input
								type='email'
								name='email'
								id='email'
								onChange={this.handleChange}
								value={this.state.email}
							/>
						</div>
						<div className='submission-row'>
							<div className='submission-item'>
								<button
									className='button'
									name='cancel'
									onClick={() => {
										this.props.toggleForgotPW();
									}}
								>
									{pagecopy.main.cancel}
								</button>
							</div>
							<div className='submission-item'>
								<button
									className='button'
									name='submit'
									onClick={(e) => {
										this.handleSubmit(e, pagecopy);
									}}
								>
									{pagecopy.main.submit}
								</button>
							</div>
						</div>
					</form>
				)}
			</>
		);
	}
}

export default ForgotPW;
