import React, { Component } from 'react';
import axios from 'axios';
import ForgotPW from '../ForgotPW/ForgotPW';
import Spinner from '../Spinner/Spinner';
import RememberLogin from '../RememberLogin/RememberLogin';
import { withRouter } from 'react-router-dom';
import './Login.css';
import Register from '../Register/Register';
import icon from '../assets/icon-chevron-right.png';
import data from '../assets/pagecopy.json';

const API_ROUTE = '/login/api/v1/';

export class Login extends Component {
	constructor() {
		super();
		this.state = {
			regularScreen: true,
			remember: false,
			forgotScreen: false,
			username: '',
			loading: true,
			loginError: false,
			errorMessage: '',
			rememberName: '',
			rememberScreen: false,
			errors: {
				username: false,
				password: false,
			},
			registerScreen: false,
		};
		this.password = React.createRef();
	}

	componentDidMount = () => {
		const queryParams = new URLSearchParams(this.props.location.search);
		let noLoginParam = false;
		let cusredParam = false;
		let cusredValue = '';
		for (let item of queryParams) {
			if (item[0] === 'nologin' && item[1] === 'true') {
				noLoginParam = true;
			}
			if (item[0] === 'cusred' && item[1]) {
				cusredParam = true;
				cusredValue = item[1];
			}
		}
		if (noLoginParam && cusredParam) {
			axios({
				url: `${API_ROUTE}checkloggedin`,
				method: 'post',
				data: {
					cusredValue,
				},
				withCredentials: true,
			}).then((res) => {
				console.log('RES!!! - ', res);
				if (
					res.data.status === 'Not Logged In' ||
					res.data.status === 'Logged In but Error'
				) {
					console.log('GO TO CHECK REMEMBER TOKEN!!!');
					this.checkRememberToken();
				} else if (res.data.data && res.data.data.status === 200) {
					window.location.replace(res.data.data.data);
				}
			});
		} else {
			this.checkRememberToken();
		}
	};

	checkRememberToken = async () => {
		this.setState({ loading: true }, () => {
			axios({
				url: `${API_ROUTE}checkremember`,
				method: 'post',
				withCredentials: true,
			})
				.then((res) => {
					let name = '';
					let rem = false;
					let reg = true;
					if (res.data.status === 'SUCCESS') {
						let str = res.data.username.toLowerCase().split(' ');
						for (var i = 0; i < str.length; i++) {
							str[i] =
								str[i].charAt(0).toUpperCase() +
								str[i].slice(1);
						}
						name = str.join(' ');
						rem = true;
						reg = false;
						this.setState({
							rememberName: name,
							loading: false,
							rememberScreen: rem,
							regularScreen: reg,
						});
					}
					this.setState({
						rememberName: name,
						loading: false,
						rememberScreen: rem,
						regularScreen: reg,
					});
				})
				.catch((error) => {
					console.error(error);
					this.setState({ loading: false });
				});
		});
	};

	handleClick = (e) => {
		if (e.target.name === 'keepLogged') {
			this.setState({
				remember: !this.state.remember,
			});
		}
		if (e.target.dataset.name === 'forgotPW') {
			this.toggleForgotPW();
		}
		if (e.target.dataset.name === 'register') {
			this.toggleRegister();
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		let errors = {
			username: false,
			password: false,
		};
		if (!this.state.username) {
			errors.username = true;
		}
		if (!this.password.current.value) {
			errors.password = true;
		}
		if (errors.username || errors.password) {
			this.setState({
				loginError: true,
				errors,
				errorMessage: '',
			});
		} else {
			this.setState({ errors, loginError: false, loading: true }, () => {
				// const queryParams = new URLSearchParams(
				// 	this.props.location.search
				// );
				// const cusred = queryParams.get('cusred');
				const params = {
					username: this.state.username,
					password: this.password.current.value,
					remlogin: this.state.remember ? 1 : 0,
				};
				// if (cusred) params.cusred = parseInt(cusred);
				const queryParams = new URLSearchParams(
					this.props.location.search
				);
				for (let item of queryParams) {
					// console.log('item = ', item);
					params[item[0]] = item[1];
				}
				// console.log('PARAMS!!! - ', params);
				axios({
					url: `${API_ROUTE}regularlogin`,
					method: 'post',
					data: {
						...params,
					},
				})
					.then((res) => {
						if (res.data.status === 'INVALID_LOGIN') {
							this.setState({
								loginError: true,
								errorMessage:
									'The login credentials provided are invalid.',
								loading: false,
							});
						} else {
							window.location.replace(res.data.redirect);
						}
					})
					.catch((error) => {
						console.error(error);
					});
			});
		}
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	toggleForgotPW = () => {
		this.setState({
			forgotScreen: !this.state.forgotScreen,
			regularScreen: !this.state.regularScreen,
		});
	};

	toggleRememberScreen = () => {
		this.setState({
			rememberScreen: !this.state.rememberScreen,
			regularScreen: !this.state.regularScreen,
		});
	};

	toggleRegister = () => {
		this.setState({
			registerScreen: !this.state.registerScreen,
			regularScreen: !this.state.regularScreen,
		});
	};

	autoLogin = (pagecopy) => {
		this.setState({ loading: true }, () => {
			const params = {};
			const queryParams = new URLSearchParams(this.props.location.search);
			for (let item of queryParams) {
				params[item[0]] = item[1];
			}
			axios({
				url: `${API_ROUTE}rememberlogin`,
				method: 'post',
				data: {
					...params,
				},
				withCredentials: true,
			})
				.then((res) => {
					if (res.data.status === 'INVALID_LOGIN') {
						this.setState({
							loginError: true,
							errorMessage: pagecopy.error,
							loading: false,
						});
					} else {
						window.location.replace(res.data.redirect);
					}
				})
				.catch((error) => {
					console.error(error);
					this.setState({ loading: false });
				});
		});
	};

	render(props) {
		const pagecopy = data[this.props.lang].login;
		return (
			<div
				className={`login-container ${
					this.state.rememberScreen
						? 'remember-me'
						: this.state.forgotScreen
						? 'forgot-pw'
						: ''
				}`}
			>
				{this.state.loading && <Spinner />}
				{this.state.forgotScreen && (
					<ForgotPW
						toggleForgotPW={this.toggleForgotPW}
						lang={this.props.lang}
					/>
				)}
				{this.state.rememberScreen && (
					<RememberLogin
						rememberName={this.state.rememberName}
						toggleRememberScreen={this.toggleRememberScreen}
						lang={this.props.lang}
					/>
				)}
				{this.state.registerScreen && (
					<Register
						toggleRegister={this.toggleRegister}
						lang={this.props.lang}
					/>
				)}
				{this.state.regularScreen && (
					<>
						<h2>{pagecopy.title}</h2>
						<p>{pagecopy.subtitle}</p>
						{this.state.loginError && (
							<>
								<p
									className={`error-message bold ${
										this.state.loginError ? 'show' : ''
									}`}
								>
									{this.state.errorMessage}
								</p>
							</>
						)}
						<form className='login-form'>
							<div className='input-row'>
								<label
									htmlFor='username'
									className='input-label'
								>
									<span className='label-text'>
										{pagecopy.username}
									</span>
									<span
										className={`label-error ${
											this.state.errors.username
												? 'show'
												: ''
										}`}
									>
										{pagecopy.required}
									</span>
								</label>
								<input
									type='text'
									name='username'
									id='username'
									onChange={this.handleChange}
									value={this.state.username}
									className={`${
										this.state.errors.username
											? 'error'
											: ''
									}`}
								/>
							</div>
							<div className='input-row'>
								<label
									htmlFor='password'
									className='input-label'
								>
									<span className='label-text'>
										{pagecopy.password}
									</span>
									<span
										className={`label-error ${
											this.state.errors.password
												? 'show'
												: ''
										}`}
									>
										{pagecopy.required}
									</span>
								</label>
								<input
									type='password'
									name='password'
									id='password'
									ref={this.password}
									className={`${
										this.state.errors.password
											? 'error'
											: ''
									}`}
								/>
							</div>
							<div className='submission-row'>
								<div className='submission-item'>
									<label
										htmlFor='keepLogged'
										className='check-label'
									>
										<input
											type='checkbox'
											name='keepLogged'
											id='keepLogged'
											className={`${
												this.state.remember
													? 'checked'
													: ''
											}`}
											onChange={this.handleClick}
											checked={this.state.remember}
										/>
										<span className='checkmark'></span>
										<span
											className='checkbox-label'
											onClick={this.handleClick}
										>
											{pagecopy.remember}
										</span>
									</label>
								</div>
								<div className='submission-item'>
									<button
										className='button'
										onClick={this.handleSubmit}
									>
										{pagecopy.button}
									</button>
								</div>
							</div>
							<div className='action-row'>
								<div
									className='action-item'
									onClick={this.handleClick}
								>
									{/* <span
										className='action-icon'
										data-name='forgotPW'
									>
										{'>'}
									</span> */}
									<img
										src={icon}
										data-name='forgotPW'
										alt='green right chevron icon'
										className='green-icon'
									/>
									<span
										className='action-text'
										data-name='forgotPW'
									>
										{pagecopy.forgotpw}
									</span>
								</div>
								<div
									className='action-item'
									onClick={this.handleClick}
								>
									{/* <span
										className='action-icon'
										data-name='register'
									>
										{'>'}
									</span> */}
									<img
										src={icon}
										data-name='register'
										alt='green right chevron icon'
										className='green-icon'
									/>
									<span
										className='action-text'
										data-name='register'
									>
										{pagecopy.register}
										<span
											className='bold'
											data-name='register'
										>
											AgSolutions.ca
										</span>
									</span>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
		);
	}
}

export default withRouter(Login);
