import React, { Component } from 'react';
import './PageWrap.css';

export class PageWrap extends Component {
	render(props) {
		return <div className='pagewrap'>{this.props.children}</div>;
	}
}

export default PageWrap;
