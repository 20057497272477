import React, { Component } from 'react';
import Header from './Header/Header';
import PageWrap from './PageWrap/PageWrap';
import Login from './Login/Login';
import NotFound from './NotFound/NotFound';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import './App.css';

class App extends Component {
	render() {
		return (
			<div className='app'>
				<Router basename='/login'>
					<Switch>
						<Route exact path='/'>
							<Redirect to='/en' />
						</Route>
						<Route path='/en'>
							<PageWrap>
								<Header />
								<Login lang='english' />
							</PageWrap>
						</Route>
						<Route path='/fr'>
							<PageWrap>
								<Header />
								<Login lang='french' />
							</PageWrap>
						</Route>
						<Route path='*'>
							<PageWrap>
								<Header />
								<NotFound />
							</PageWrap>
						</Route>
					</Switch>
				</Router>
			</div>
		);
	}
}

export default App;
