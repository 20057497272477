import React, { Component } from 'react';
import Spinner from '../Spinner/Spinner';
import axios from 'axios';
import './Register.css';
import data from '../assets/pagecopy.json';

const API_ROUTE = '/login/api/v1/';

const PROVINCES_EN = [
	{ id: 'AB', label: 'Alberta' },
	{ id: 'BC', label: 'British Columbia' },
	{ id: 'MB', label: 'Manitoba' },
	{ id: 'NB', label: 'New Brunswick' },
	{ id: 'NL', label: 'Newfoundland and Labrador' },
	{ id: 'NS', label: 'Nova Scotia' },
	{ id: 'ON', label: 'Ontario' },
	{ id: 'PE', label: 'Prince Edward Island' },
	{ id: 'QC', label: 'Quebec' },
	{ id: 'SK', label: 'Saskatchewan' },
];

const PROVINCES_FR = [
	{ id: 'AB', label: 'Alberta' },
	{ id: 'BC', label: 'Colombie-Britannique' },
	{ id: 'MB', label: 'Manitoba' },
	{ id: 'NB', label: 'Nouveau-Brunswick' },
	{ id: 'NL', label: 'Terre-Neuve-et-Labrador' },
	{ id: 'NS', label: 'Nouvelle-Écosse' },
	{ id: 'ON', label: 'Ontario' },
	{ id: 'PE', label: "l'île du Prince-Édouard" },
	{ id: 'QC', label: 'Québec' },
	{ id: 'SK', label: 'Saskatchewan' },
];

export class Register extends Component {
	constructor() {
		super();
		this.state = {
			farmName: '',
			firstName: '',
			lastName: '',
			email: '',
			address1: '',
			address2: '',
			box: '',
			state: '',
			city: '',
			zip: '',
			phone: '',
			errors: {
				isErrors: false,
				fields: {
					firstName: false,
					lastName: false,
					emailReq: false,
					emailInv: false,
					address1: false,
					state: false,
					city: false,
					zipReq: false,
					zipInv: false,
					phoneReq: false,
					phoneInv: false,
				},
			},
			registerError: false,
			errorMessage: '',
			loading: false,
			initial: true,
			success: false,
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	validateForm = () => {
		const phoneRe = /^\d{3}-?\d{3}-?\d{4}$/;
		const emailRe =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const zipRe =
			/^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1}[ ]{0,1}\d{1}[A-Za-z]{1}\d{1}$/;
		const phone = this.state.phone.replace(' ', '-').replace('.', '-');
		const errors = {
			isErrors: false,
			fields: {
				firstName: false,
				lastName: false,
				emailReq: false,
				emailInv: false,
				address1: false,
				state: false,
				city: false,
				zipReq: false,
				zipInv: false,
				phoneReq: false,
				phoneInv: false,
			},
		};
		if (!this.state.firstName) {
			errors.isErrors = true;
			errors.fields.firstName = true;
		}
		if (!this.state.lastName) {
			errors.isErrors = true;
			errors.fields.lastName = true;
		}
		if (!this.state.email) {
			errors.isErrors = true;
			errors.fields.emailReq = true;
		} else if (!emailRe.test(this.state.email)) {
			errors.isErrors = true;
			errors.fields.emailInv = true;
		}
		if (!this.state.address1) {
			errors.isErrors = true;
			errors.fields.address1 = true;
		}
		if (!this.state.state) {
			errors.isErrors = true;
			errors.fields.state = true;
		}
		if (!this.state.city) {
			errors.isErrors = true;
			errors.fields.city = true;
		}
		if (!this.state.zip) {
			errors.isErrors = true;
			errors.fields.zipReq = true;
		} else if (!zipRe.test(this.state.zip)) {
			errors.isErrors = true;
			errors.fields.zipInv = true;
		}
		if (!this.state.phone) {
			errors.isErrors = true;
			errors.fields.phoneReq = true;
		} else if (!phoneRe.test(phone)) {
			errors.isErrors = true;
			errors.fields.phoneInv = true;
		}
		return errors;
	};

	handleSubmit = async (e, pagecopy) => {
		e.preventDefault();
		const errors = this.validateForm();
		if (errors.isErrors) {
			this.setState({ errors });
		} else {
			this.setState({ errors, loading: true }, () => {
				const params = {
					farmName: this.state.farmName,
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					email: this.state.email,
					address1: this.state.address1,
					address2: this.state.address2,
					box: this.state.box,
					state: this.state.state,
					city: this.state.city,
					zip: this.state.zip,
					phone: this.state.phone,
				};
				axios({
					url: `${API_ROUTE}register`,
					method: 'post',
					data: {
						...params,
					},
				})
					.then((res) => {
						if (res.data) {
							this.setState({
								loading: false,
								initial: false,
								success: true,
							});
						} else {
							this.setState({
								loading: false,
								registerError: true,
								errorMessage: pagecopy.error,
							});
						}
					})
					.catch((error) => {
						console.error(error);
						this.setState({ loading: false });
					});
			});
		}
	};

	render(props) {
		const pagecopy = data[this.props.lang].register;
		const success = (
			<>
				<h2>
					{pagecopy.title}
					<span className='bold'>AgSolutions.ca</span>
				</h2>
				<div className='success-content'>
					<h3>{pagecopy.success1}</h3>
					<p>{pagecopy.success2}</p>
					<p>
						{pagecopy.success3}
						<span className='bold'>
							AgSolutions
							<span className='superScript'>{`${
								this.props.lang === 'french' ? 'MD' : '®'
							}`}</span>
						</span>{' '}
						{pagecopy.success4}
					</p>
				</div>
				<div className='submission-row'>
					<div className='submission-item'>
						<button
							className='button success'
							name='cancel'
							onClick={() => {
								this.props.toggleRegister();
							}}
						>
							{pagecopy.backButton}
						</button>
					</div>
				</div>
			</>
		);
		return (
			<>
				{this.state.loading && <Spinner />}
				{this.state.success && success}
				{this.state.initial && (
					<>
						<h2>
							{pagecopy.title}
							<span className='bold'>AgSolutions.ca</span>
						</h2>
						<div className='information-container'>
							<div className='information-item'>
								<h4>{pagecopy.retailer}</h4>
								<p>
									{pagecopy.retailText1}
									<span className='bold'>AgSolutions</span>
									<span className='superScript'>{`${
										this.props.lang === 'french'
											? 'MD'
											: '®'
									}`}</span>
									{pagecopy.retailText2}
								</p>
							</div>
							<div className='information-item'>
								<h4>{pagecopy.grower}</h4>
								<p>
									{pagecopy.growerText1}
									<span className='bold'>AgSolutions</span>
									{pagecopy.growerText2}
								</p>
							</div>
						</div>
						<div className='form-container'>
							<p>{pagecopy.reqText}</p>
							<form className='register-form'>
								<div className='input-row'>
									<div className='input-item full'>
										<label
											htmlFor='farmName'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.farmName}
											</span>
										</label>
										<input
											type='text'
											name='farmName'
											id='farmName'
											onChange={this.handleChange}
											value={this.state.farmName}
											className={`${
												this.state.errors.fields
													.farmName
													? 'error'
													: ''
											}`}
										/>
									</div>
								</div>
								<div className='input-row'>
									<div className='input-item half'>
										<label
											htmlFor='firstName'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.firstName}*
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.firstName
														? 'show'
														: ''
												}`}
											>
												{pagecopy.required}
											</span>
										</label>
										<input
											type='text'
											name='firstName'
											id='firstName'
											onChange={this.handleChange}
											value={this.state.firstName}
											className={`${
												this.state.errors.fields
													.firstName
													? 'error'
													: ''
											}`}
										/>
									</div>
									<div className='input-item half'>
										<label
											htmlFor='lastName'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.lastName}*
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.lastName
														? 'show'
														: ''
												}`}
											>
												{pagecopy.required}
											</span>
										</label>
										<input
											type='text'
											name='lastName'
											id='lastName'
											onChange={this.handleChange}
											value={this.state.lastName}
											className={`${
												this.state.errors.fields
													.lastName
													? 'error'
													: ''
											}`}
										/>
									</div>
								</div>
								<div className='input-row'>
									<div className='input-item half'>
										<label
											htmlFor='email'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.email}*
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.emailReq
														? 'show'
														: ''
												}`}
											>
												{pagecopy.required}
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.emailInv
														? 'show'
														: ''
												}`}
											>
												{pagecopy.invalid}
											</span>
										</label>
										<input
											type='text'
											name='email'
											id='email'
											onChange={this.handleChange}
											value={this.state.email}
											className={`${
												this.state.errors.fields
													.emailReq ||
												this.state.errors.fields
													.emailInv
													? 'error'
													: ''
											}`}
										/>
									</div>
									<div className='input-item half'>
										<label
											htmlFor='address1'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.address} 1*
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.address1
														? 'show'
														: ''
												}`}
											>
												{pagecopy.required}
											</span>
										</label>
										<input
											type='text'
											name='address1'
											id='address1'
											onChange={this.handleChange}
											value={this.state.address1}
											className={`${
												this.state.errors.fields
													.address1
													? 'error'
													: ''
											}`}
										/>
									</div>
								</div>

								<div className='input-row'>
									<div className='input-item half'>
										<label
											htmlFor='address2'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.address} 2
											</span>
										</label>
										<input
											type='text'
											name='address2'
											id='address2'
											onChange={this.handleChange}
											value={this.state.address2}
										/>
									</div>
									<div className='input-item half'>
										<label
											htmlFor='box'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.box}
											</span>
										</label>
										<input
											type='text'
											name='box'
											id='box'
											onChange={this.handleChange}
											value={this.state.box}
										/>
									</div>
								</div>

								<div className='input-row'>
									<div className='input-item half'>
										<label
											htmlFor='state'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.province}*
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.state
														? 'show'
														: ''
												}`}
											>
												{pagecopy.required}
											</span>
										</label>
										<select
											className={`provinces-dropdown ${
												this.state.errors.fields.state
													? 'error'
													: ''
											}`}
											name='state'
											onChange={this.handleChange}
										>
											<option value=''>
												{pagecopy.dropdown}
											</option>
											{this.props.lang === 'english'
												? PROVINCES_EN.map((prov) => (
														<option
															value={prov.id}
															key={prov.id}
														>
															{prov.label}
														</option>
												  ))
												: PROVINCES_FR.map((prov) => (
														<option
															value={prov.id}
															key={prov.id}
														>
															{prov.label}
														</option>
												  ))}
										</select>
									</div>
									<div className='input-item half'>
										<label
											htmlFor='city'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.city}*
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.city
														? 'show'
														: ''
												}`}
											>
												{pagecopy.required}
											</span>
										</label>
										<input
											type='text'
											name='city'
											id='city'
											onChange={this.handleChange}
											value={this.state.city}
											className={`${
												this.state.errors.fields.city
													? 'error'
													: ''
											}`}
										/>
									</div>
								</div>

								<div className='input-row'>
									<div className='input-item half'>
										<label
											htmlFor='zip'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.postal}*
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.zipReq
														? 'show'
														: ''
												}`}
											>
												{pagecopy.required}
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.zipInv
														? 'show'
														: ''
												}`}
											>
												{pagecopy.invalidPostal}
											</span>
										</label>
										<input
											type='text'
											name='zip'
											id='zip'
											onChange={this.handleChange}
											value={this.state.zip}
											className={`${
												this.state.errors.fields
													.zipReq ||
												this.state.errors.fields.zipInv
													? 'error'
													: ''
											}`}
										/>
									</div>
									<div className='input-item half'>
										<label
											htmlFor='phone'
											className='input-label'
										>
											<span className='label-text'>
												{pagecopy.phone}*
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.phoneReq
														? 'show'
														: ''
												}`}
											>
												{pagecopy.required}
											</span>
											<span
												className={`label-error ${
													this.state.errors.fields
														.phoneInv
														? 'show'
														: ''
												}`}
											>
												{pagecopy.invalidPhone}
											</span>
										</label>
										<input
											type='text'
											name='phone'
											id='phone'
											onChange={this.handleChange}
											value={this.state.phone}
											className={`${
												this.state.errors.fields
													.phoneReq ||
												this.state.errors.fields
													.phoneInv
													? 'error'
													: ''
											}`}
										/>
									</div>
								</div>

								<p
									className={`register-error ${
										this.state.registerError ? 'show' : ''
									}`}
								>
									{this.state.errorMessage}
								</p>

								<div className='submission-row'>
									<div className='submission-item'>
										<button
											className='button'
											onClick={() => {
												this.props.toggleRegister();
											}}
										>
											{pagecopy.cancel}
										</button>
									</div>
									<div className='submission-item'>
										<button
											className='button'
											onClick={(e) => {
												this.handleSubmit(e, pagecopy);
											}}
										>
											{pagecopy.register}
										</button>
									</div>
								</div>
							</form>
						</div>
					</>
				)}
			</>
		);
	}
}

export default Register;
