import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

class NotFound extends Component {
	render() {
		return (
			<div className='login-container not-found'>
				<div>
					<h2>The page you selected could not be found.</h2>
					<p>Please click below to return to the login page.</p>
				</div>
				<div>
					<Link to='/'>
						<button className='button'>Back to login page</button>
					</Link>
				</div>
			</div>
		);
	}
}

export default NotFound;
