import React, { Component } from 'react';
import axios from 'axios';
import Spinner from '../Spinner/Spinner';
import { withRouter } from 'react-router-dom';
import icon from '../assets/icon-chevron-right.png';
import './RememberLogin.css';
import data from '../assets/pagecopy.json';

const API_ROUTE = '/login/api/v1/';

export class RememberLogin extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			loginError: false,
			errorMessage: '',
		};
	}

	componentDidMount = () => {};

	rememberLogin = (pagecopy) => {
		this.setState({ loading: true }, () => {
			// const queryParams = new URLSearchParams(this.props.location.search);
			// const cusred = queryParams.get('cusred');
			const params = {};
			// if (cusred) params.cusred = parseInt(cusred);
			const queryParams = new URLSearchParams(this.props.location.search);
			for (let item of queryParams) {
				// console.log('item = ', item);
				params[item[0]] = item[1];
			}
			axios({
				url: `${API_ROUTE}rememberlogin`,
				method: 'post',
				data: {
					...params,
				},
				withCredentials: true,
			})
				.then((res) => {
					if (res.data.status === 'INVALID_LOGIN') {
						this.setState({
							loginError: true,
							errorMessage: pagecopy.error,
							loading: false,
						});
					} else {
						window.location.replace(res.data.redirect);
					}
				})
				.catch((error) => {
					console.error(error);
					this.setState({ loading: false });
				});
		});
	};

	render(props) {
		const pagecopy = data[this.props.lang].rememberlogin;
		return (
			<>
				{this.state.loading && <Spinner />}
				<h2>
					{pagecopy.title}
					{this.props.rememberName}
				</h2>
				{this.state.loginError && (
					<>
						<p className='error-message bold'>
							{this.state.errorMessage}
						</p>
					</>
				)}
				<div className='submission-row'>
					<div className='submission-item'>
						<div
							className='action-item'
							onClick={() => {
								this.props.toggleRememberScreen();
							}}
						>
							{/* <span
								className='action-icon'
								data-name='switchUser'
							>
								{'>'}
							</span> */}
							<img
								src={icon}
								data-name='switchUser'
								alt='green right chevron icon'
								className='green-icon'
							/>
							<span
								className='action-text'
								data-name='switchUser'
							>
								{pagecopy.switch}
							</span>
						</div>
					</div>
					<div className='submission-item'>
						<button
							className='button'
							onClick={() => {
								this.rememberLogin(pagecopy);
							}}
						>
							{pagecopy.login}
						</button>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(RememberLogin);
