import React, { Component } from 'react';
import './Header.css';

export class Header extends Component {
	render() {
		return <div className='header'></div>;
	}
}

export default Header;
